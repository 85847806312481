/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/docs/en/main/file-conventions/entry.client
 */

import { startTransition, StrictMode, useEffect } from 'react';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/remix';
import { isbot } from 'isbot';
import mixpanel from 'mixpanel-browser';
import { hydrateRoot } from 'react-dom/client';

const isBot = isbot(window.navigator.userAgent);

if (!isBot && window.env.SITE_ENV !== 'local') {
  Sentry.init({
    environment: window.env.SITE_ENV,
    dsn: window.env.EMBEDDED_SENTRY_DSN,
    tracesSampleRate: 1,
    profilesSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.remixRouterInstrumentation(useEffect, useLocation, useMatches),
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
      //? Disabled until we can prove no PII captured/Cookie required
      // new Sentry.Replay({
      //   // Additional SDK configuration goes in here, for example:
      //   maskAllText: true,
      //   blockAllMedia: true,
      //   maskAllInputs: true,
      // }),
    ],
  });
}

mixpanel.init(window.env.MIXPANEL_PROJECT_TOKEN_EMBEDDED, {
  batch_requests: false,
  track_pageview: false,
  disable_persistence: true,
  opt_out_persistence_by_default: true,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
